import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "swiper/css";
import "swiper/css/free-mode";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper/modules";
import { Autoplay } from "swiper/modules";
import clsx from "clsx";
import LazyImage from "../components/v4/LazyLoadImage/LazyImage";
import { useInView } from "react-intersection-observer";

import transformImg1 from "../images/v4/whatsappPage/images/transformImg1.png";
import transformImg2 from "../images/v4/whatsappPage/images/transformImg2.png";
import transformImg3 from "../images/v4/whatsappPage/images/transformImg3.png";

import supportImg1 from "../images/v4/whatsappPage/images/supportImg1.png";
import supportImg2 from "../images/v4/whatsappPage/images/supportImg2.png";
import supportImg3 from "../images/v4/whatsappPage/images/supportImg3.png";

import wpAdPageBanner from "../images/v4/whatsappPage/images/wpAdPageBanner.webp";
import wpAdPageBannerMobile from "../images/v4/whatsappPage/images/wpAdPageBannerMobile.webp";

import IMDA_SME_Large from "../images/v2/psg/IMDA_SME_Large.webp";

import adLandingpageBackground1 from "../images/v4/whatsappPage/images/adLandingpageBackground1.png";

import whiteCheckCircle from "../images/v4/whatsappPage/icons/whiteCheckCircle.svg";

import partnerIcon1 from "../images/v4/aiPage/icons/partnerIcon1.png";
import partnerIcon2 from "../images/v4/aiPage/icons/partnerIcon2.png";
import partnerIcon3 from "../images/v4/aiPage/icons/partnerIcon3.png";
import partnerIcon5 from "../images/v4/aiPage/icons/partnerIcon5.png";
import partnerIcon6 from "../images/v4/aiPage/icons/partnerIcon6.png";
import partnerIcon7 from "../images/v4/aiPage/icons/partnerIcon7.png";
import partnerIcon8 from "../images/v4/aiPage/icons/partnerIcon8.png";
import FaqSection from "../components/v4/FaqSection/FaqSection";

import companyLogo from "../images/v4/logos/companyLogo.svg";

import trustedIcon from "../images/v4/logos/trustedIcon.svg";
import trustedIcon1 from "../images/v4/logos/trustedIcon1.png";
import trustedIcon2 from "../images/v4/logos/trustedIcon2.png";
import trustedIcon3 from "../images/v4/logos/trustedIcon3.png";
import trustedIcon4 from "../images/v4/logos/trustedIcon4.png";
import trustedIcon5 from "../images/v4/logos/trustedIcon5.png";

import experienceImg1 from "../images/v4/whatsappPage/images/experienceImg1.png";
import experienceImg2 from "../images/v4/whatsappPage/images/experienceImg2.png";
import experienceImg3 from "../images/v4/whatsappPage/images/experienceImg3.png";
import experienceImg4 from "../images/v4/whatsappPage/images/experienceImg4.png";

import wpLandingAdPageBg from "../images/v4/whatsappPage/images/wpLandingAdPageBg.png";

import greenCheckIcon from "../images/v3/icons/GreenCheckIcon.webp";

import { Collapse, Divider, makeStyles } from "@material-ui/core";

import Link from "../components/v2/Link";
import downArrow from "../images/v4/aiPage/icons/downArrow.svg";

import wpBannerImg from "../images/v4/whatsappPage/images/wpBannerImg.png";
import bannerBgImg2 from "../images/v4/whatsappPage/images/bannerBgImg2.png";

import whatsappBusinessPartner from "../images/v3/singaporeCrmHomepage/whatsappBusinessPartner.webp";
import metaBusinessPartner from "../images/v3/singaporeCrmHomepage/metaBusinessPartner.webp";

import wpAdPageAnimation from "../animations/wpAdPageAnimation.json";
import wpAdPageAnimationMobile from "../animations/wpAdPageAnimationMobile.json";
import LottieIcon from "../components/v4/LottieIcon";
import whatsAppFeatureIcon from "../images/v4/homepage/icons/whatsAppFearureIcon.svg";
import SEO from "../components/SEO";
import TruestedSection from "../components/v4/TrustedSection/TruestedSection";

import callImg from "../images/v2/campaigns/callNew.svg";

export const conversionEventOnCall = () => {
  if (typeof window.gtag === "function")
    window.gtag("event", "conversion", {
      send_to: "AW-615460128/prVoCM7gjuwBEKDavKUC",
    });
};

const useStyles = makeStyles((theme) => ({
  broadcastContainer: {
    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "32px",
      width: "70%",
      margin: "0 auto",
      textAlign: "center",
    },
    "@media only screen and (max-width: 600px)": {
      "& .centerContainer": {
        width: "100%",
      },
    },
  },
  broadcastBox: {
    display: "flex !important",
    padding: "32px",
    background: "#009249",
    alignItems: "flex-start",
    borderRadius: "20px",
    justifyContent: "flex-start",
    minWidth: "85%",
    maxWidth: "85%",
    transition: "0.3s ease",
    "&.active": {
      transform: "scale(1) !important",
    },
    "&.inActive": {
      transform: "scale(0.8) !important",
    },
    "@media only screen and (max-width: 600px)": {
      padding: "15px",
      gap: "24px",
      flexDirection: "column",
      minHeight: "700px",
    },
  },
  cardPointer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    "@media only screen and (max-width: 600px)": {
      alignItems: "baseline",
    },
  },
  broadcastContainerInner: {
    width: "100%",
    padding: "0 4rem 4rem !important",
    "@media only screen and (max-width: 600px)": {
      padding: "0 15px 40px !important",
    },
    "@media only screen and (min-width: 1900px)": {
      padding: "0 19rem 6rem !important",
    },
  },
  broadcastLeftContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "60%",
    gap: "32px",
    textAlign: "left",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  broadcastRightContainer: {
    width: "40%",
    "& img": {
      width: "100%",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      "& img": {
        width: "100%",
      },
    },
  },
  broadcastImg: {
    "& img": {
      width: "100%",
      position: "relative",
      bottom: "0",
    },
  },
  partnerSlide: {
    width: "185px !important",
    "& img": {
      width: "185px",
      filter: "brightness(0.1)",
    },
    "@media only screen and (max-width: 600px)": {
      width: "140px !important",
      marginRight:'30px !important',
      "& img": {
        width: "140px",
        filter: "brightness(0.1)",
      },
    },
  },
  partnerSlider: {
    padding: "10px 0 80px !important",
  },
  pointerImg: {
    "& img": {
      width: "28px",
    },
    "@media only screen and (max-width: 600px)": {
      "& img": {
        width: "10px",
      },
    },
  },
  pointers: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "20px",
  },
  aIPowered: {
    background: `url(${adLandingpageBackground1})`,
    backgroundSize: "cover",
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
    borderRadius: "24px",
    "& .centerContainer": {
      textAlign: "center",
    },
    "@media only screen and (max-width: 600px)": {
      borderRadius: "16px",
    },
  },
  statsContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "60px",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "15px",
    },
  },
  statsBox: {
    width: "24%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "20px",
    background: "#0020205e",
    borderRadius: "30px",
    padding: "20px",
    minHeight: "320px",
    backdropFilter: "blur(30px)",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      gap: "15px",
      minHeight: "auto",
    },
    "& .border": {
      border: "1px solid #fefefe1c",
    },
  },
  statsBoxBody: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "5px",
    "& p": {
      color: "#B6DDFF",
    },
    "& p:last-child": {
      color: "#9DBACF",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      "& ul": {
        paddingLeft: "0",
      },
      "& li": {
        marginBottom: "10px",
      },
    },
  },
  statsFont: {
    fontSize: "80px",
    color: "#B6DDFF",
    display: "flex",
    alignItems: "baseline",
    "@media only screen and (max-width: 600px)": {
      fontSize: "60px",
    },
  },
  supportSystem: {
    background: "#1D0240",
    borderRadius: "24px",
    paddingBottom: "200px",
    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "32px",
      width: "80%",
      margin: "0 auto",
      textAlign: "center",
    },
    "@media only screen and (max-width: 600px)": {
      borderRadius: "16px",
      "& .centerContainer": {
        width: "100%",
      },
    },
  },
  tabsContainer: {
    width: "80%",
    marginTop: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px",
    border: "2px solid #DBDBDB",
    borderRadius: "40px",
    background: "#fff",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      padding: "5px",
      marginTop: "0px",
      overflow: "auto",
      gap: "15px",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tab: {
    width: "33%",
    background: "",
    padding: "18px",
    background: "transparent",
    borderRadius: "40px",
    background: "transparent",
    transition: "0.3s ease",
    cursor: "pointer",
    "&.active": {
      color: "white",
      background:
        "linear-gradient(90deg, #FF682A 0%, #FF7056 35%, #FF682A 73%, #FF550F 100%)",
    },
    "@media only screen and (max-width: 600px)": {
      minWidth: "max-content",
      padding: "10px",
    },
  },
  supportBox: {
    top: "412px",
    width: "100%",
    display: "flex",
    padding: "40px",
    position: "absolute",
    background: "#5e278ae0",
    alignItems: "center",
    borderRadius: "20px",
    backdropFilter: "blur(30px)",
    justifyContent: "space-evenly",
    "& $broadcastLeftContainer":{
      width:'45%',
      "@media only screen and (max-width: 600px)": {
        width:'100%'
      }
    },
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      width: "94%",
      padding: "15px",
      top: "280px",
    },
  },
  smeSection: {
    background: "#FAF2FF",
    paddingTop: "370px",
    "& .container": {
      paddingBottom: "40px",
    },

    "& .centerContainer": {
      marginTop: "120px",
      "@media only screen and (max-width: 600px)": {
        marginTop: "60px",
      },
    },
    "@media only screen and (max-width: 600px)": {
      paddingTop: "540px",
    },
  },
  smeImage: {
    background: "white",
    width: "80%",
    cursor: "pointer",
    "@media only screen and (max-width: 600px)": {
      width:'100%'
    }
  },
  smeSectionInner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    "& p": {
      width: "70%",
      textAlign: "center",
      "@media only screen and (max-width: 600px)": {
        width: "100%",
      },
    },
  },
  commonPageFooterSection: {
    background: "linear-gradient(89deg, #015352 0%, #00926A 100%)",
    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "32px",
      width: "80%",
      margin: "0 auto",
      textAlign: "center",
    },
    "@media only screen and (max-width: 600px)": {
      "& .centerContainer": {
        width: "100%",
      },
    },
  },
  headerContainer: {
    width: "100%",
    margin: "auto",
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    zIndex: "100",
    transition: "0.5s ease",
  },
  headerContainerInner: {
    position: "relative",
    width: "100%",
    padding: "10px 32px",
    maxWidth: "1200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "rgb(7 34 53 / 90%)",
    backdropFilter: "blur(10px)",
    borderRadius: "0px 0px 16px 16px",
    transition: "0.3s ease",
    "&.glassEffect": {
      background: "rgb(7 34 53 / 70%)",
      backdropFilter: "blur(7px) contrast(1)",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    },
    "&.darkBackground": {
      background: "rgb(7 34 53 / 100%)",
    },
    "@media only screen and (max-width: 600px)": {
      padding: "10px 15px",
    },
  },
  rightNavigation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "20px",
    "& a": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: "5px",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
        color: "white",
      },

    },
    "& .primaryButton": {
      zIndex: "2",
      "@media only screen and (max-width: 600px)": {
        padding: "10px 15px",
        fontSize: "12px",
      },
    },
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "5px",
      "& p:first-child":{
        display:'none'
      }
    },
  },
  pageFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",

    background: "linear-gradient(90deg, #011D32 0%, #00182B 100%)",
    "& p": {
      color: "#9097A6",
      "@media only screen and (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    "& a": {
      color: "#9097A6",
      "@media only screen and (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    "& .container": {
      padding: "30px 0",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "@media only screen and (max-width: 600px)": {
        gap: "10px",
        padding: "15px",
        alignItems: "flex-start",
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap",
      },
    },
  },
  whatsappSectionContainer: {
    backgroundImage: `url(${wpLandingAdPageBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "70px 0 0 0",
    "& .container": {
      paddingBottom: "32px",
    },
    "@media only screen and (max-width: 600px)": {
      padding: "64px 0 0 0",
      "& .container": {
        paddingBottom: "15px",
      },
    },
  },
  whatsappContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: "25px",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "32px",
    },
  },
  whatsappContainerLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "51%",
    gap: "16px",
    flexDirection: "column",
    "& .highlighterText": {
      background: "linear-gradient(90deg, #084FFF 0%, #00BFA5 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      gap: "15px",
    },
  },
  whatsappContainerRight: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  formContainer: {
    width: "100%",
    background: "#fff",
    boxShadow: "0px 0px 21px #00000029",
    borderRadius: "0 0 20px 20px",
    padding: "0px",
    height: "100%",
    minHeight: "470px",
    "@media only screen and (max-width: 600px)": {
      height: "470px",
      padding: "0",
    },

    overflow: "visible",
  },
  tagsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    width: "80%",
    gap: "12px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  tag: {
    padding: "10px",
    borderRadius: "20px",
    background: "#CBF0EA5C",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "10px",
  },
  formHeader: {
    textAlign: "center",
    padding: "20px",
    background: "linear-gradient(90deg, #0C408D 0%, #028161 100%)",
    borderRadius: "20px 20px 0 0",
    boxShadow: "0px 0px 21px #00000029",
    "& span": {
      color: "#5CFFB2",
    },
    "@media only screen and (max-width: 600px)": {
      padding: "12px",
    },
  },
  experienceContainer: {
    "& .centerContainer": {
      width: "70%",
      margin: "0 auto",
      textAlign: "center",
      "@media only screen and (max-width: 600px)": {
        width: "100%",
      },
    },
  },
  experienceInnerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "40px",
  },
  experienceContainerLeft: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "24px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      marginTop: "30px",
    },
  },
  faqBox: {
    borderBottom: "1px solid #C4E4DC",
    paddingBottom: "14px",
    position: "relative",
    transition: "0.3s ease",
    "&::after": {
      left: "0",
      bottom: "-2px",
      width: "0",
      height: "3px",
      content: '""',
      position: "absolute",
      transition: "0.3s ease",
      borderRadius: "10px",
      background:
        "linear-gradient(90deg, #25D366 0%, #08B57B 66%, #72E9B2 100%)",
    },
    "&.active::after": {
      transition: "5.5s ease",
      width: "100%",
    },
  },
  faqHeader: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  experienceContainerRight: {
    width: "40%",
    minHeight: "450px",
    "& img": {
      width: "100%",
      height: "100%",
    },
    "@media only screen and (max-width: 600px)": {
      display: "none",
    },
  },
  downArrow: {
    transition: "0.3s ease",
    "&.active": {
      transform: "rotate(180deg)",
    },
  },
  body: {
    textAlign: "left",
    margin: "10px 0 0 ",
    "& img": {
      display: "none",
    },
    "@media only screen and (max-width: 600px)": {
      "& img": {
        display: "block",
        width: "100%",
        marginTop: "15px",
      },
    },
  },
  leftNavigation: {
    "@media only screen and (max-width: 600px)": {
      "& img": {
        width: "150px",
      },
    },
  },
  wpFaq: {
    "@media only screen and (max-width: 600px)": {
      "& section": {
        padding: "0px 0 0 !important",
      },
    },
  },
  rewards: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "flex-start",
    gap: "10px",
    boxShadow: "0px 0px 6px #00000029",
    padding: "8px 15px",
    borderRadius: "100px",
    width: "60%",
    background: "#fff",
    "& .border": {
      borderRight: "1px solid #DBDBDB",
      width: "0",
    },
    "& img": {
      width: "47%",
      "@media only screen and (max-width: 600px)": {
        width: "45%",
      },
    },
  },
  bannerSection: {
    "& .centerContainer": {
      textAlign: "center",
      margin:'0 auto',
      width:'70%',
      "& img": {
        width: "56px",
        position: "relative",
        top: "10px",
        "@media only screen and (max-width: 600px)": {
          width: "24px",
          top: "3px",
        },
      },
      "@media only screen and (max-width: 600px)": {
        "& h2": {
          fontSize: "28px",
        },
        width: "80%",
        margin: "auto",
      },
    },
    "& .container": {
      padding: "130px 0",
      "@media only screen and (max-width: 600px)": {
        padding: "4.5rem 15px",
      },
    },
    width: "100%",
    "@media only screen and (min-width: 1900px)": {
      "& .container": {
        padding: "190px 0",
      },
    },
    // backgroundColor: "#00926A",
    // backgroundImage: `url(${bannerBgImg2})`,
    // backgroundSize: "cover",
    // backgroundRepeat: "repeat-x",
    // backgroundPosition: "center",
    // WebkitAnimation: "slidein 80s",
    // animation: "slidein 10s",
    // WebkitAnimationFillMode: "forwards",
    // animationFillMode: "forwards",
    // WebkitAnimationIterationCount: "infinite",
    // animationIterationCount: "infinite",
    // WebkitAnimationDirection: "alternate",
    // animationDirection: "alternate",
    // backgroundSpacing: "10px", // Add spacing between repeated background images
  },
  trustedSection: {
    marginTop: "0px",
  },
  backgroundAnimation: {
    position: "absolute",
    width: "100%",
    display: "block",
    "@media only screen and (max-width: 600px)": {
      display: "none",
    },
  },
  backgroundAnimationMobile: {
    display: "none",
    position: "absolute",
    width: "100%",
    "@media only screen and (max-width: 600px)": {
      display: "block",
    },
  },
  belowText: {
    width: "100%",
    textAlign: "right",
    color: "#6691b7",
    marginTop: "10px",
  },
  bannerImgSection:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    width:'100%',
    '& img':{
        width:'90%'
    },
    "@media only screen and (max-width: 600px)": {
      widht:'100%'
    }
  },
  mobImg:{
    display:'none',
    "@media only screen and (max-width: 600px)": {
      display:'block'
    }
  },
  webImg:{
    display:'block',
    "@media only screen and (max-width: 600px)": {
      display:'none'
    }
  }
}));

const WpAdLandingPage = () => {
  const classes = useStyles();

  const partners = [
    { icon: partnerIcon1, alt: "caffeine solutions - Pepper Cloud Customer" },
    { icon: partnerIcon2, alt: "cuscapi - Pepper Cloud Customer" },
    { icon: partnerIcon3, alt: "TechData - Pepper Cloud Customer" },
    { icon: partnerIcon5, alt: "sis - Pepper Cloud Customer" },
    {
      icon: partnerIcon6,
      alt: "mustard seed systems corporation - Pepper Cloud Customer",
    },
    {
      icon: partnerIcon7,
      alt: "Singapore Maritime Foundation - Pepper Cloud Customer",
    },
    { icon: partnerIcon8, alt: "urban moods - Pepper Cloud Customer" },
    { icon: partnerIcon1, alt: "caffeine solutions - Pepper Cloud Customer" },
    { icon: partnerIcon2, alt: "cuscapi - Pepper Cloud Customer" },
    { icon: partnerIcon3, alt: "TechData - Pepper Cloud Customer" },
    { icon: partnerIcon5, alt: "sis - Pepper Cloud Customer" },
    {
      icon: partnerIcon6,
      alt: "mustard seed systems corporation - Pepper Cloud Customer",
    },
    {
      icon: partnerIcon7,
      alt: "Singapore Maritime Foundation - Pepper Cloud Customer",
    },
    { icon: partnerIcon8, alt: "urban moods - Pepper Cloud Customer" },
  ];

  const broadcastList = [
    {
      heading: "Automate growth with WhatsApp CRM",
      points: [
        "Run WhatsApp marketing campaigns in seconds via broadcasts.",
        "Automate lead capture, bookings, and more with WhatsApp forms",
        "Auto-respond with media-rich message templates and interactive buttons.",
        "Promote and sell instantly with product catalogs.",
        "Track response time, resolution time, etc. with message dashboard.",
      ],
      img: transformImg1,
      alt: "WhatsApp CRM for sales and marketing",
      background: "linear-gradient(180deg, #F77F4C 0%, #9227E9 100%)",
    },
    {
      heading: "Sell smarter with AI CRM capabilities",
      points: [
        "Create records, tasks, and follow-ups with one-click task management.",
        "Summarise conversations using natural language processing (NLP).",
        "Respond to queries during off-business hours with AssistAI chatbot.",
        "Reply to customers within seconds using generative AI.",
        "Create comprehensive lead profiles with data mining capabilities.",
      ],
      img: transformImg2,
      alt: "AI-powered WhatsApp CRM",
      background: "linear-gradient(180deg, #00B181 0%, #1448C4 100%)",
    },
    {
      heading: "Turn leads into revenue with Sales CRM",
      points: [
        "Instantly capture and qualify leads 24/7.",
        "Customise sales pipelines to fit your business requirements.",
        "Forecast sales with pinpoint accuracy at every stage.",
        "Track sales performance and team productivity with visual dashboards.",
        "Automate lead assignments, task reminders, follow-ups, etc.",
      ],
      img: transformImg3,
      alt: "Best sales CRM",
      background: "linear-gradient(180deg, #B74AF5 0%, #5E02A8 100%)",
    },
  ];
  const supportList = [
    {
      heading: "Best CRM for beauty salons",
      desc: "Beauty salons use AI-powered CRM with WhatsApp integration to boost appointment bookings and enhance client satisfaction.",
      point: [
        "Enable easy appointment bookings via WhatsApp.",
        "Broadcast offers and promotions at a scale to drive more bookings.",
        "Send appointment reminders and collect customer feedback effortlessly.",
      ],
      img: supportImg1,
      alt: "WhatsApp CRM for beauty salons",
    },
    {
      heading: "Smart CRM for food delivery services",
      desc: "Food delivery services can use WhatsApp AI CRM to streamline communication, and provide personalised experiences.",
      point: [
        "Auto-send order confirmations, delivery updates, and tracking links.",
        "Respond automatically to customer queries with AI chatbot.",
        "Gather customer preferences by conducting interactive polls or surveys.",
      ],
      img: supportImg2,
      alt: "WhatsApp CRM for food delivery services",
    },
    {
      heading: "All-in-one CRM for travel agencies",
      desc: "Travel agencies deliver personalised, efficient, and real-time customer engagement with AI-powered WhatsApp CRM.",
      point: [
        "Send personalised tour recommendations based on customer preferences.",
        "Collect customer documents via WhatsApp and streamline ticket bookings.",
        "Share travel itineraries and blast promotional campaigns via WhatsApp.",
      ],
      img: supportImg3,
      alt: "WhatsApp CRM for travel agencies",
    },
  ];
  const statsBoxContent = [
    {
      heading: "Reduction in response times",
      number: "70",
      content:
        "Improve response efficiency by cutting down delays significantly.",
      color: "#FFDFD2",
    },
    {
      heading: "Boost in sales revenue",
      number: "57",
      content:
        "Enhance sales performance and drive revenue growth with intelligent customer interaction.",
      color: "#F1DFFF",
    },
    {
      heading: "Reduction in operational cost",
      number: "30",
      content:
        "Streamline operations to save costs with automation and efficiency.",
      color: "#CEE9FF",
    },
    {
      heading: "Increase in conversion rates",
      number: "20",
      content:
        "Drive better customer engagement to improve conversion rates effectively.",
      color: "#BEE7E7",
    },
  ];
  const faqs = [
    {
      question: "I’m an SME. How can WhatsApp CRM benefit SMEs?",
      answer:
        "WhatsApp CRM system can be an effective sales tool for SMEs. With WhatsApp CRM, you can provide personalised customer service, streamline your sales process, and track all customer interactions in one place. With features such as message templates, automated replies, chatbots, and tags, you can save time and focus on growing your business.",
    },
    {
      question: "How does AI help in sales?",
      answer:
        "AI in sales leverages artificial intelligence to optimise sales processes. With Pepper Cloud CRM generative AI - AssistAI, you can automate  chatflows using AI chatbot, manage routine tasks (such as capturing leads, answering common queries, etc.) without any manual intervention, analyse customer data, personalise communication, and boost overall sales effectiveness.",
    },
    {
      question: "Can I use my existing number for WhatsApp CRM integration?",
      answer:
        "Yes, you can use your existing number for WhatsApp in Pepper Cloud WhatsApp CRM.",
    },
    {
      question:
        "Can all members of my team communicate with customers through one WhatsApp number?",
      answer:
        "Yes, WhatsApp CRM enables your entire team to chat with leads and customers from a single WhatsApp number.",
    },
    {
      question: "How long does it take to set up WhatsApp for my business?",
      answer:
        "If you have all the prerequisites ready, you can start using WhatsApp through the CRM system within a few hours.",
    },
    {
      question:
        "Does Pepper Cloud charge for WhatsApp messages sent through the CRM?",
      answer:
        "No, Pepper Cloud doesn’t charge for the messages. However, you may incur charges from WhatsApp.",
    },
    {
      question:
        "What other channel integrations are available in the Pepper Cloud CRM system?",
      answer:
        "Pepper Cloud CRM is a multi-channel integrated platform that integrates with WhatsApp, Facebook Messenger, Instagram, Telegram, and others.",
    },
    {
      question: "Can I use the PSG grant to avail Pepper Cloud CRM?",
      answer:
        "Pepper Cloud CRM is an IMDA-approved digital solution, and you can use up to a 50% PSG grant to avail it.",
    },
    {
      question: "Is Pepper Cloud CRM mobile-friendly?",
      answer: (
        <>
          Yes, you can download Pepper Cloud CRM{" "}
          <a
            target="_blank"
            href="https://peppercloud.com/best-mobile-crm-system-app-ios-android"
          >
            mobile app
          </a>
          . It enables you to manage records and conversations while on the go.
        </>
      ),
    },
  ];

  const experienceList = [
    {
      title: "One account, multiple agents",
      img: experienceImg1,
      alt: "One WhatsApp account, Multi agent access",
      desc: "Empower your agents to handle customer inquiries seamlessly from any device using a single WhatsApp account.",
    },
    {
      title: "Built for SMEs",
      alt: "CRM for small business",
      img: experienceImg2,
      desc: "Capture and convert leads, maintain client profiles, and win sales conversations with best CRM for WhatsApp.",
    },
    {
      title: "Omnichannel CRM",
      alt: "Omnichannel CRM software",
      img: experienceImg3,
      desc: "Integrate email, social media channels, voice calling and more. Unify every touchpoint using Pepper Cloud CRM. ",
    },
    {
      title: "24/7 Local support",
      alt: "Pepper Cloud - 24X7 Local Support",
      img: experienceImg4,
      desc: "Get instant responses to your queries through multiple channels, including messages, calls, and emails.",
    },
  ];
  const [activeRealWorld, setActiveRealWorld] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [activeAccordian, setActiveAccordian] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setScrollPosition(currentScrollY);
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        // setSubMenuOpen(false);
        // setIsVisible(false);
      } else {
        // setSubMenuOpen(false);
        // setIsVisible(true);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);
  const { ref, inView } = useInView({
    threshold: 0.5, // adjust as needed
    triggerOnce: false,
  });

  // When the section is in view, set up a timer to auto change the active accordion every 5 seconds.
  useEffect(() => {
    let timer;
    if (inView) {
      timer = setInterval(() => {
        setActiveAccordian((prev) => (prev + 1) % experienceList.length);
      }, 5500);
    }
    // Clear the timer when the component unmounts or when inView changes.
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [inView, experienceList.length]);
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  React.useEffect(() => {
    const pcIframe = document.getElementById("pc-iframe");
    pcIframe.src = pcIframe.src + window.location.search;
    if (window.location.search)
      pcIframe.src = pcIframe.src + "&page_title=" + document.title;
    else pcIframe.src = pcIframe.src + "?page_title=" + document.title;
  }, []);
  return (
    <main>
      <SEO
        title={
          "#1 AI-Powered Sales CRM with WhatsApp Integration | Pepper Cloud"
        }
        pathname={"https://peppercloud.com/crm-with-whatsapp-integration"}
        keywords="whatsapp crm, crm whatsapp integration, whatsapp integration, WhatsApp-enabled CRM"
        description={
          "Pepper Cloud CRM software with WhatsApp Integration to maximise your sales productivity. Manage your entire team's communications in one simple and unified platform."
        }
        ogData={{
          "og:image": [
            "/meta/og/1X1/home.png",
            "/meta/og/1024X512/home.png",
            "/meta/og/1200X630/home.png",
          ],
        }}
        jsonLd={{
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "Pepper Cloud",
          legalName: "Pepper Cloud PTE LTD",
          url: "https://peppercloud.com/",
          logo: "https://peppercloud.com/icons/logo.png",
          foundingDate: "2017",
          address: [
            {
              "@type": "PostalAddress",
              streetAddress: "#04-01/02",
              addressLocality: "Blk 79 Ayer Rajah Crescent",
              addressRegion: "SG",
              postalCode: "139955",
              addressCountry: "Singapore",
            },
            {
              "@type": "PostalAddress",
              streetAddress:
                "2nd Floor, Salarpuria Tower 1, 7th Block Koramangala",
              addressLocality: "Bengaluru",
              addressRegion: "KA",
              postalCode: "560095",
              addressCountry: "India",
            },
          ],
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer support",
            telephone: "+6566562616",
            email: ["sales@peppercloud.com", "support@peppercloud.com"],
          },
          sameAs: [
            "https://twitter.com/peppercloudpte",
            "https://www.linkedin.com/company/peppercloud/",
            "https://www.facebook.com/PepperCloudPteLtd",
            "https://www.instagram.com/pepper.cloud/",
          ],
        }}
      />
      <div className={classes.headerContainer}>
        <div
          className={clsx(
            classes.headerContainerInner,
            scrollPosition > 200 && "glassEffect"
          )}
        >
          <div className={classes.leftNavigation}>
            <Link to="/">
              <img
                src={companyLogo}
                alt="Pepper Cloud - Best Sales CRM Software"
              />
            </Link>
          </div>
          <div className={classes.rightNavigation}>
            <p className="font-20 font-white font-wix-semibold">
              Book a free demo
            </p>
            <a href="tel:+6597510475" onClick={conversionEventOnCall}>
              <img src={callImg} />
              <p className="font-20 font-wix-medium font-white">+65 97510475</p>
            </a>
          </div>
        </div>
      </div>
      <section className={classes.whatsappSectionContainer}>
        <div className="container">
          <div className={classes.whatsappContainer}>
            <div className={classes.whatsappContainerLeft}>
              <motion.h1
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-semibold font-64 color-black1"
              >
                Drive sales with{" "}
                <span className="highlighterText">AI+WhatsApp </span> CRM
              </motion.h1>
              <motion.p
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-regular font-20 color-black2"
              >
                Accelerate end-to-end customer journey with AI-powered WhatsApp
                CRM. Connect instantly, automate effortlessly, and transform
                every conversation into a sales opportunity.
              </motion.p>
              <div className={classes.tagsContainer}>
                <div className={classes.tag}>
                  <img src={greenCheckIcon} alt="pointer" />
                  <p className="font-16 font-black2 font-wix-regular">
                    Up to 50% PSG grant
                  </p>
                </div>
                <div className={classes.tag}>
                  <img src={greenCheckIcon} alt="pointer" />
                  <p className="font-16 font-black2 font-wix-regular">
                    Broadcast messages
                  </p>
                </div>
                <div className={classes.tag}>
                  <img src={greenCheckIcon} alt="pointer" />
                  <p className="font-16 font-black2 font-wix-regular">
                    24/7 Local support
                  </p>
                </div>
                <div className={classes.tag}>
                  <img src={greenCheckIcon} alt="pointer" />
                  <p className="font-16 font-black2 font-wix-regular">
                    Built for SMEs
                  </p>
                </div>
              </div>
              <div className={classes.rewards}>
                <img
                  src={whatsappBusinessPartner}
                  alt="WhatsApp Business Partner"
                />
                <div className="border" />
                <img src={metaBusinessPartner} alt="Meta Business Partner" />
              </div>
            </div>
            <div className={classes.whatsappContainerRight}>
              <div className={classes.formHeader}>
                <p className="font-24 font-white font-wix-semibold">
                  Avail up to <span>50%</span> PSG Grant
                </p>
              </div>
              <div className={classes.formContainer}>
                <iframe
                  width={"100%"}
                  height={"470px"}
                  style={{ border: "none" }}
                  id="pc-iframe"
                  src="https://app.peppercloud.com/form/40d71e54-e07a-43dd-a94c-f3ae429540b6/embed"
                />
              </div>
            </div>
          </div>
          <div className={classes.bannerImgSection}>
          <img
            src={ wpAdPageBannerMobile}
            className={classes.mobImg}
            alt="CRM with WhatsApp integration"
          />
          <img
            src={wpAdPageBanner}
            className={classes.webImg}
            alt="CRM with WhatsApp integration"
          />
          </div>
        </div>
      </section>
      <section className={classes.bannerSection}>
        <LottieIcon
          className={classes.backgroundAnimation}
          animationData={wpAdPageAnimation}
          autoplay
          loop
          play
          hover={false}
        />
        <LottieIcon
          className={classes.backgroundAnimationMobile}
          animationData={wpAdPageAnimationMobile}
          autoplay
          loop
          play
          hover={false}
        />
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-semibold font-56 font-white"
            >
              <img src={whatsAppFeatureIcon} alt="WhatsApp CRM integration" />{" "}
              WhatsApp Sales CRM suite with 100+ powerful features
            </motion.h2>
          </div>
        </div>
      </section>
      <section className={classes.trustedSection}>
        <TruestedSection />
      </section>
      <section className={classes.broadcastContainer}>
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-semibold font-56 text-black1"
            >
              Transform chaos into clarity with all-in-one sales CRM
            </motion.h2>
          </div>
        </div>
        <Swiper
          slidesPerView={1.1}
          centeredSlides={true}
          spaceBetween={30}
          grabCursor={true}
          loop={false}
          speed={5000}
          modules={[Autoplay]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          // freeMode={true}
          className={classes.broadcastContainerInner}
          breakpoints={{
            // For smaller screens (mobile)
            0: {
              slidesPerView: 1.1,
              slidesPerGroup: 1,
              freeMode: true,
              grabCursor: false,
              allowTouchMove: true,
              spaceBetween: 10,
            },
            // For larger screens (desktop)
            768: {
              slidesPerView: 1.1,
              centeredSlides: true,
              spaceBetween: 30,
              grabCursor: true,
            },
          }}
        >
          {broadcastList.map((point, index) => (
            <SwiperSlide
              className={classes.broadcastBox}
              style={{ background: point.background }}
            >
              <div className={classes.broadcastLeftContainer}>
                <p className="font-40 font-wix-medium font-white">
                  {point.heading}
                </p>
                <div className={classes.pointers}>
                  {point.points.map((content) => (
                    <div className={classes.cardPointer}>
                      <LazyImage
                        source={whiteCheckCircle}
                        className={classes.pointerImg}
                        alt={"pointer"}
                      />
                      <p className="font-wix-regular font-18 font-white">
                        {content}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className={classes.broadcastRightContainer}>
                <img
                  src={point.img}
                  alt={point.alt}
                  className={classes.broadcastImg}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      <section className={classes.aIPowered}>
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-semibold font-56 font-white"
            >
              Get immediate results with AI-powered WhatsApp CRM
            </motion.h2>
          </div>
          <div className={classes.statsContainer}>
            {statsBoxContent.map((stat, index) => (
              <motion.div
                whileInView={{ opacity: [0, 1], y: [50, 0] }}
                transition={{ duration: 1, delay: index * 0.1 }}
                className={classes.statsBox}
              >
                <p className={clsx(classes.statsFont, "font-wix-medium")}>
                  {stat.number}{" "}
                  <span className="font-56 font-wix-medium">%</span>
                </p>
                <div className="border" />
                {/* <div className={classes.statsBoxHeader}>
                 
                </div> */}
                <div className={classes.statsBoxBody}>
                  <p className="font-18 color-purple3 font-wix-semibold">
                    {stat.heading}
                  </p>
                  <p className="font-16 color-purple3 font-wix-regular">
                    {stat.content}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
          <div className={classes.belowText}>
            <p className="font-12 font-wix-regular">
              * Statistics from multiple sources
            </p>
          </div>
        </div>
      </section>
      <section className={classes.experienceContainer} ref={ref}>
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-semibold font-56 text-black1"
            >
              Experience enterprise power with SME simplicity
            </motion.h2>
          </div>
          <div className={classes.experienceInnerContainer}>
            <div className={classes.experienceContainerLeft}>
              {experienceList.map((faq, index) => (
                <motion.div
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  transition={{ duration: 1, delay: index * 0.01 }}
                  className={clsx(
                    classes.faqBox,
                    index === activeAccordian && "active"
                  )}
                  onClick={() => setActiveAccordian(index)}
                >
                  <div className={classes.faqHeader}>
                    <h3 className="font-20 font-wix-semibold">{faq.title}</h3>
                    <img
                      src={downArrow}
                      alt="Arrow"
                      className={clsx(
                        classes.downArrow,
                        index === activeAccordian && "active"
                      )}
                    />
                  </div>
                  <Collapse
                    className={classes.body}
                    in={activeAccordian === index}
                  >
                    <p className="font-18 font-wix-regular color-black2">
                      {faq.desc}
                    </p>
                    <motion.img
                      initial={{ opacity: 1, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      src={faq.img}
                      alt={faq.alt}
                    />
                  </Collapse>
                </motion.div>
              ))}
            </div>
            <div className={classes.experienceContainerRight}>
              {experienceList.map(
                (experience, index) =>
                  index === activeAccordian && (
                    <motion.img
                      initial={{ opacity: 1, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      src={experience.img}
                      alt={experience.alt}
                    />
                  )
              )}
            </div>
          </div>
        </div>
      </section>
      <section className={classes.supportSystem}>
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-semibold font-56 font-white"
            >
              AI+WhatsApp+Sales CRM: Built to support all industries
            </motion.h2>
            <div className={classes.tabsContainer}>
              <div
                className={clsx(
                  classes.tab,
                  "font-wix-medium",
                  "font-20",
                  activeRealWorld === 0 && "active"
                )}
                onClick={() => setActiveRealWorld(0)}
              >
                Beauty salons
              </div>
              <div
                className={clsx(
                  classes.tab,
                  "font-wix-medium",
                  "font-20",
                  activeRealWorld === 1 && "active"
                )}
                onClick={() => setActiveRealWorld(1)}
              >
                Food delivery
              </div>
              <div
                className={clsx(
                  classes.tab,
                  "font-wix-medium",
                  "font-20",
                  activeRealWorld === 2 && "active"
                )}
                onClick={() => setActiveRealWorld(2)}
              >
                Travel agencies
              </div>
            </div>
          </div>
          {supportList.map(
            (point, index) =>
              activeRealWorld === index && (
                <motion.div
                  initial={{ opacity: 1, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className={classes.supportBox}
                  // style={{ background: point.background }}
                >
                  <div className={classes.broadcastLeftContainer}>
                    <p className="font-40 font-wix-regular font-white">
                      {point.heading}
                    </p>
                    <p className="font-18 font-wix-regular font-white">
                      {point.desc}
                    </p>
                    <div className={classes.pointers}>
                      {point.point.map((content) => (
                        <div className={classes.cardPointer}>
                          <LazyImage
                            source={whiteCheckCircle}
                            className={classes.pointerImg}
                            alt={"pointer"}
                          />
                          <p className="font-wix-regular font-18 font-white">
                            {content}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={classes.broadcastRightContainer}>
                    <LazyImage
                      source={point.img}
                      alt={point.alt}
                      className={classes.broadcastImg}
                    />
                  </div>
                </motion.div>
              )
          )}
        </div>
      </section>
      <section className={classes.smeSection}>
        <div className="container">
          <div className={classes.smeSectionInner}>
            <img
              src={IMDA_SME_Large}
              className={classes.smeImage}
              alt="IMDA Pre-approved Solution Provider"
              onClick={() =>
                window.open(
                  "https://services2.imda.gov.sg/CTOaaS/DigitalHealthCheck?utm_source=PA+vendor&utm_medium=vendor+site&utm_campaign=SN222"
                )
              }
            />
            <motion.p className="font-18 font-wix-medium color-black1">
              SMEs are eligible for up to 50% Productivity Solutions Grant (PSG)
              support for the adoption of Pepper Cloud CRM solution, a
              Pre-Approved Solution under the IMDA SMEs Go Digital programme.
            </motion.p>
          </div>
          <div className="centerContainer">
            <motion.h2 className="font-24 font-wix-medium color-black1 text-center">
              Best CRM tool with AI and WhatsApp capabilities trusted by many
              clients
            </motion.h2>
          </div>
        </div>
        <Swiper
          className={classes.partnerSlider}
          slidesPerView="auto"
          spaceBetween={80}
          loop={true}
          speed={2000}
          modules={[Autoplay]}
          allowTouchMove={false}
          autoplay={{
            delay: 100,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
            breakpoints: {
              0: {
                spaceBetween: 20,
              },
            },
          }}
        >
          {partners.map((partner) => (
            <SwiperSlide className={classes.partnerSlide}>
              <img src={partner.icon} alt={partner.alt} />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      <div className={classes.wpFaq}>
        <FaqSection
          backgroundColor={"#FFFFFF"}
          heading={"Frequently asked questions - AI-powered WhatsApp CRM"}
          faqs={faqs}
        />
      </div>
      <section className={classes.commonPageFooterSection}>
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="font-wix-medium font-56 font-white"
            >
              Boost your sales with AI-powered WhatsApp Sales CRM
            </motion.h2>
            <button
              className="primaryButton font-20 font-wix-medium font-white"
              onClick={() => scrollToTop()}
            >
              Get started
            </button>
          </div>
        </div>
      </section>
      <div className={classes.pageFooter}>
        <div className="container">
          <p className="font-14 font-wix-regular">
            © Pepper Cloud 2025 - Best AI-powered WhatsApp Sales CRM Software
            Singapore
          </p>
          {/* <Link className="font-14 font-wix-regular">Site map</Link> */}
        </div>
      </div>
    </main>
  );
};

export default WpAdLandingPage;
